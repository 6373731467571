export const ApiLink = {
    office: "https://localhost:7129/api/OfficeLocation",
    employedetails:"https://localhost:7129/api/EmployeeDetails",
    role:"https://localhost:7129/api/Role",
    account:"https://localhost:7129/api/AccountDetails",
    department:"https://localhost:7129/api/Department",
    roledetail:"https://localhost:7129/api/RoleDetail",
    leaderemployee:"https://localhost:7129/api/LeaderAndEmployee",
    accessories:"https://localhost:7129/api/Accessory",
    brand:"https://localhost:7129/api/Brand",
    consoles:"https://localhost:7129/api/Console",
    address:'https://localhost:7129/api/Address',
    holiday:"https://localhost:7129/api/Holiday" ,
    employeeleave:"https://localhost:7129/api/EmployeeHoliday",
    productsDetail:"https://localhost:7129/api/ProductsDetail",
    // productstoragelocation:"https://localhost:7129/api/ProductStorageLocation",
    login:"https://localhost:7129/api/Login",
    loginauth:"https://localhost:7129/api/Login/login",
    leavetable:"https://localhost:7129/api/LeaveTable",
    otpapi:"https://localhost:7129/api/Otp",
    // employeeaccessories:"https://localhost:7129/api/Employeeaccessory",
    employeeleavehistory:"https://localhost:7129/api/EmployeeLeaveHistory",
    leaveconfirmationemail:"https://localhost:7129/api/LeaveConfirmationEmail",
    productsrepairhistory:"https://localhost:7129/api/ProductsRepairHistory",
    salary:"https://localhost:7129/api/Salary",
    pdf:"https://localhost:7129/api/PdfGenerator"
  };